import pick from 'lodash/pick';
import uniqBy from 'lodash/uniqBy';
import rangy from 'rangy';
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';

import { useHighlights } from '../../../../shared/foreground/database/helperHooks';
import {
  useDocumentContentFromState,
  usePartialDocument,
} from '../../../../shared/foreground/stateHooks';
import { RangyRange } from '../../../../shared/foreground/types/rangy';
import getRangyClassApplier from '../../../../shared/foreground/utils/getRangyClassApplier';
import { compareSerializedLocations, serializeRange } from '../../../../shared/foreground/utils/locationSerializer';
import { BaseDocument, Category, FirstClassDocument, PartialDocument } from '../../../../shared/types';
import { isFirstClassDocument, notEmpty } from '../../../../shared/typeValidators';
import getDocumentAuthor from '../../../../shared/utils/getDocumentAuthor';
import getDocumentTitle from '../../../../shared/utils/getDocumentTitle';
import { HEADING_TAG_SELECTOR, parseHeadingLevel } from '../../../../shared/utils/tableOfContents';
import { DocumentFrontMatter } from '../DocumentFrontMatter';
import {
  NotebookContentHeading,
  NotebookContentHighlight,
  NotebookContentItem,
  NotebookContentView,
  removeConsecutiveHeadings,
} from './NotebookContentView';
import styles from './NotebookView.module.css';

function serializeHeadingElementLocation(headingElement: Element, contentRoot: HTMLDivElement): undefined | string {
  const range = rangy.createRange();
  const nodeToSelect = headingElement.childNodes[0];
  if (!nodeToSelect) {
    return undefined;
  }
  range.selectNodeContents(nodeToSelect);
  return serializeRange({
    classApplier: getRangyClassApplier(),
    containerNode: contentRoot,
    range: range as RangyRange,
  });
}

function findEpubHeadingItems(
  contentRoot: HTMLDivElement,
  parentDocument: PartialDocument<FirstClassDocument, 'source_specific_data'>,
): NotebookContentHeading[] {
  const tocItems = parentDocument.source_specific_data?.epub?.toc ?? [];
  return tocItems
    .map((tocItem) => {
      const headingElement = contentRoot.querySelector(`[data-rw-epub-toc="${tocItem.id}"]`);
      if (headingElement === null) {
        return undefined;
      }
      const location = serializeHeadingElementLocation(headingElement, contentRoot);
      if (location === undefined) {
        return undefined;
      }
      const heading: NotebookContentHeading = {
        type: 'heading',
        location,
        content: tocItem.title,
        level: tocItem.level,
      };
      return heading;
    })
    .filter(notEmpty);
}

function findHeadingItems(contentRoot: HTMLDivElement): NotebookContentHeading[] {
  const headingElements = contentRoot.querySelectorAll(HEADING_TAG_SELECTOR);
  return Array.from(headingElements, (headingElement): NotebookContentHeading | undefined => {
    const content = headingElement.textContent;
    if (content === null || content === '') {
      return undefined;
    }
    const location = serializeHeadingElementLocation(headingElement, contentRoot);
    if (location === undefined) {
      return undefined;
    }
    const level = parseHeadingLevel(headingElement as HTMLElement);
    return {
      type: 'heading',
      content,
      level,
      location,
    };
  }).filter(notEmpty);
}

function SingleParentContentGenerator({ parentDocId, onContentItemsGenerated }: {
  parentDocId: BaseDocument['id'];
  onContentItemsGenerated: (contentItems: NotebookContentItem[]) => void;
}): ReactElement {
  const [parentDocument] = usePartialDocument(parentDocId, [
    'children',
    'category',
    'source_specific_data',
  ]);

  const parentContentRef = useRef<HTMLDivElement | null>(null);

  const { content: parentDocumentContent } = useDocumentContentFromState(parentDocId);

  const headings: NotebookContentHeading[] = useMemo(() => {
    const contentRoot = parentContentRef.current;
    if (!contentRoot || !parentDocument) {
      return [];
    }
    if (parentDocument.category === Category.PDF) {
      return []; // TODO
    }
    if (parentDocument.category === Category.EPUB) {
      return findEpubHeadingItems(contentRoot, parentDocument);
    }
    return findHeadingItems(contentRoot);
    // Need this to query heading elements once parent document content is loaded. Otherwise, the array stays empty forever.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentDocument, parentContentRef, parentContentRef.current?.innerHTML]);

  const highlightDocuments = useHighlights({ parentDocId });

  const highlights: NotebookContentHighlight[] = useMemo(() => {
    const highlights = uniqBy(
      highlightDocuments
        .map((highlightDocument): NotebookContentHighlight => ({
          type: 'highlight',
          ...pick(highlightDocument, 'id', 'location', 'content', 'markdown', 'tags', 'children', 'parent', 'offset'),
        })),
        'id',
    );
    if (parentDocument?.category === Category.PDF) {
      // PDF highlights don't have serialized locations, so we must sort them by offset.
      highlights.sort((a, b) => a.offset - b.offset);
    }
    return highlights;
  }, [highlightDocuments, parentDocument?.category]);

  useEffect(() => {
    let contentItems = (headings as NotebookContentItem[]).concat(highlights);
    if (contentItems.length === 0) {
      return;
    }
    if (parentDocument?.category !== Category.PDF) {
      contentItems.sort((a, b) =>
        compareSerializedLocations(a.location, b.location) ?? 1);
      contentItems = contentItems.filter((item) => item.type === 'highlight' || item.level <= 2);
      contentItems = removeConsecutiveHeadings(contentItems);
    }
    onContentItemsGenerated(contentItems);
  }, [headings, highlights, onContentItemsGenerated, parentDocument?.category]);

  return <div
    className={styles.hiddenParentDocument}
    ref={parentContentRef}
    dangerouslySetInnerHTML={{ __html: parentDocumentContent ?? '' }} />;
}

export function SingleParentNotebook({
  parentDocId,
  onContentItemsGenerated,
  scrollableAncestorRef,
  setFocusedItemIndex,
}: {
  parentDocId: BaseDocument['id'];
  onContentItemsGenerated?: (contentItems: NotebookContentItem[]) => void;
  scrollableAncestorRef: React.RefObject<HTMLDivElement>;
  setFocusedItemIndex: (index: number) => void;
}): ReactElement {
  const [parentDocument] = usePartialDocument(parentDocId, [
    'id',
    'category',
    'children',
    'title',
    'author',
    'published_date',
    'overrides',
  ]);

  const numberOfHighlights = parentDocument?.children?.length ?? 0;

  const [lastHighlight] = usePartialDocument(parentDocument?.children?.[numberOfHighlights - 1], ['saved_at']);
  const [contentItems, setContentItems] = useState<NotebookContentItem[]>([]);

  useEffect(() => {
    if (!onContentItemsGenerated || contentItems.length === 0) {
      return;
    }
    onContentItemsGenerated(contentItems);
  }, [contentItems, onContentItemsGenerated]);

  const title = useMemo(() => getDocumentTitle(parentDocument), [parentDocument]);
  const author = useMemo(() => getDocumentAuthor(parentDocument), [parentDocument]);

  if (!parentDocument || !isFirstClassDocument(parentDocument)) {
    return <div>Invalid document</div>;
  }

  return <div className={styles.notebookContainer}>
    <div className={styles.notebook}>
      <DocumentFrontMatter
        docId={parentDocument.id}
        isNotebookView
        title={title}
        author={author || 'Unknown'}
        publishedOrLastHighlightDate={lastHighlight?.saved_at}
        category={parentDocument.category}
        numberOfHighlights={numberOfHighlights}
      />
      <NotebookContentView
        contentItems={contentItems}
        scrollableAncestorRef={scrollableAncestorRef}
        setFocusedItemIndex={setFocusedItemIndex}
      />
      <SingleParentContentGenerator parentDocId={parentDocId} onContentItemsGenerated={setContentItems} />
    </div>
  </div>;
}
