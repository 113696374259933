import React from 'react';

import Icon from './Icon';

export default function HighlightNoteIcon({ className }: {className: string;}) {
  return <Icon text="highlight-notes">
    <span className={className}>
      <svg
        width="16" height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 2C3.34315 2 2 3.34315 2 5V9C2 10.6569 3.34315 12 5 12H6L8 15L10 12H11C12.6569 12 14 10.6569 14 9V5C14 3.34315 12.6569 2 11 2H5Z" />
      </svg>
  </span>
</Icon>;
}
