import React from 'react';

import Icon from './Icon';

export default function HighlightTagsIcon({ className }: {className: string;}) {
  return <Icon text="highlight-tags">
    <span className={className}>
      <svg
        width="16" height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.5858 7.58579L8.58579 2.58579C8.21071 2.21071 7.70201 2 7.17157 2H4C2.89543 2 2 2.89543 2 4V7.17157C2 7.70201 2.21071 8.21071 2.58579 8.58579L7.58579 13.5858C8.36684 14.3668 9.63316 14.3668 10.4142 13.5858L13.5858 10.4142C14.3668 9.63317 14.3668 8.36684 13.5858 7.58579ZM6 7C6.55228 7 7 6.55228 7 6C7 5.44771 6.55228 5 6 5C5.44772 5 5 5.44771 5 6C5 6.55228 5.44772 7 6 7Z"
          clipRule="evenodd" fillRule="evenodd" />
      </svg>
  </span>
</Icon>;
}
