import type { ReactElement } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useHistory, useParams } from 'react-router-dom';

import { useDocument } from '../../../../shared/foreground/stateHooks';
import { setFocusedDocumentId } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { createToast } from '../../../../shared/foreground/toasts.platform';
import {
  BaseDocument,
  FirstClassDocument,
  NotebookKind,
  ShortcutId,
} from '../../../../shared/types';
import { IMG_REGEX } from '../../../../shared/utils/highlightMarkdownToHtml';
import { createHeadingIdFromIndex } from '../../../../shared/utils/tableOfContents';
import urlJoin from '../../../../shared/utils/urlJoin';
import { AdaptiveHeaderContext } from '../../contexts';
import { useAppearanceStyleHotkeys } from '../../hooks/appearanceStyles';
import { useIsLeftSidebarHidden } from '../../hooks/hooks';
import { useShortcutsMap } from '../../utils/shortcuts';
import AdaptiveHeader, { useHeaderIsHidden } from '../AdaptiveHeader';
import { NotebookAside } from '../Aside';
import TableOfContents from '../TableOfContents';
import { NotebookContentItem } from './NotebookContentView';
import { createHighlightHashId } from './notebookHelpers';
import styles from './NotebookView.module.css';
import { SingleParentNotebook } from './SingleParentNotebook';

export type NotebookRouteParams = {
  notebookKind: NotebookKind.SingleParent;
  notebookId: BaseDocument['id'];
};

export function NotebookPage(): ReactElement {
  const { notebookKind, notebookId } = useParams<NotebookRouteParams>();
  const history = useHistory<{ parentPath?: string; }>();
  const scrollableAncestorRef = useRef<HTMLDivElement>(null);
  const parentDocId = notebookKind === NotebookKind.SingleParent ? notebookId : undefined;
  const cachedParentPath = useMemo(() => history.location.state?.parentPath ?? urlJoin(['/read', parentDocId]),
    // We want to store the parentPath from location state when component is mounted.
    // When location state is cleared (e.g. by navigating to #hl__someHighlightId), this doesn't change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  [parentDocId]);

  const [notebookContentItems, setNotebookContentItems] = useState<NotebookContentItem[]>([]);
  const [focusedItemIndex, setFocusedItemIndex] = useState<number>(0);

  useAppearanceStyleHotkeys(false);
  const leftSidebarHidden = useIsLeftSidebarHidden();

  const tableOfContents = useMemo(() =>
    <NotebookTableOfContents contentItems={notebookContentItems} focusedItemIndex={focusedItemIndex} />,
    [notebookContentItems, focusedItemIndex]);
  const adaptiveHeaderValue = useHeaderIsHidden({ scrollableAncestorRef });
  const [parentDocument] = useDocument<FirstClassDocument>(parentDocId);

  useEffect(() => {
    setFocusedDocumentId(
      null,
      {
        userInteraction: null,
      },
    );
  }, []);

  const shortcutMap = useShortcutsMap();

  useHotkeys(
    shortcutMap[ShortcutId.Esc],
    useCallback(() => {
      history.push(cachedParentPath);
    }, [history, cachedParentPath]),
);

  const onContentItemsGenerated = useCallback((contentItems) => {
    setNotebookContentItems(contentItems);
  }, []);

  if (parentDocument?.children?.length === 0) {
    createToast({
      category: 'info',
      content: 'No highlights left, opening document',
    });
    history.push(cachedParentPath);
    return <div />;
  }
  return <div className={`has-visible-scrollbar ${styles.scrollableAncestor}`} ref={scrollableAncestorRef}>
    <AdaptiveHeaderContext.Provider value={adaptiveHeaderValue}>
      <div className={styles.notebookPage}>
        <NotebookAside
          parentPath={cachedParentPath}
          sidebarsHidden={leftSidebarHidden}
          tableOfContents={tableOfContents}
        />
        <div className={`${styles.contentContainer} ${leftSidebarHidden ? styles.sidebarsHidden : ''}`}>
          <AdaptiveHeader doc={parentDocument} parentPath={cachedParentPath} isNotebookView />
          <SingleParentNotebook
            parentDocId={notebookId}
            onContentItemsGenerated={onContentItemsGenerated}
            scrollableAncestorRef={scrollableAncestorRef}
            setFocusedItemIndex={setFocusedItemIndex}
          />
        </div>
      </div>
    </AdaptiveHeaderContext.Provider>
  </div>;
}

export function NotebookTableOfContents({ contentItems, focusedItemIndex }: {
  contentItems: NotebookContentItem[];
  focusedItemIndex: number;
}): ReactElement {
  const notebookContentElements = useMemo(() =>
    contentItems.map((item) => {
      const tag = item.type === 'heading' ? 'h1' : 'h6';
      const element = document.createElement(tag);
      const content = (item.content ?? '').trim();
      if (content === '' && item.type === 'highlight' && IMG_REGEX.test(item.markdown)) {
        element.innerText = '(Image)';
      } else {
        element.innerText = content;
      }
      return element;
    }), [contentItems]);
  const notebookContentRoot = useMemo(() => {
    const div = document.createElement('div');
    div.append(...notebookContentElements);
    return div;
  }, [notebookContentElements]);
  const focusedElement = useMemo(() =>
    notebookContentElements[focusedItemIndex],
    [notebookContentElements, focusedItemIndex]);
  const createHeadingId = useCallback((index: number) => {
    const contentItem = contentItems[index];
    switch (contentItem.type) {
      case 'heading':
        return createHeadingIdFromIndex(index);
      case 'highlight':
        return createHighlightHashId(contentItem.id);
    }
  }, [contentItems]);
  return <TableOfContents
    contentRoot={notebookContentRoot}
    currentFocusedElement={focusedElement}
    createHeadingId={createHeadingId}
  />;
}

