import clone from 'lodash/clone';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useHistory } from 'react-router-dom';

import { useHighlightNoteText } from '../../../../shared/foreground/database/helperHooks';
import foregroundEventEmitter from '../../../../shared/foreground/eventEmitter';
import {
  deleteHighlight,
} from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/highlight';
import { setHighlightIdToOpenAt } from '../../../../shared/foreground/stateUpdaters/transientStateUpdaters/other';
import { TextHighlightElement } from '../../../../shared/foreground/types';
import { Highlight, ShortcutId } from '../../../../shared/types';
import { notEmpty } from '../../../../shared/typeValidators';
import highlightMarkdownToHtml from '../../../../shared/utils/highlightMarkdownToHtml';
import makeLogger from '../../../../shared/utils/makeLogger';
import { parseHeadingIdToIndex } from '../../../../shared/utils/tableOfContents';
import urlJoin from '../../../../shared/utils/urlJoin';
import { useAppearanceStyles } from '../../hooks/appearanceStyles';
import { useHotKeys } from '../../hooks/hooks';
import { useShortcutsMap } from '../../utils/shortcuts';
import Button from '../Button';
import { positionFocusIndicator } from '../ContentFocusIndicator';
import contentFocusIndicatorStyles from '../ContentFocusIndicator.module.css';
import { DeleteHighlightDialog } from '../DeleteHighlightDialog';
import HighlighterPopovers from '../HighlighterPopovers';
import HighlightNoteIcon from '../icons/HighlightNoteIcon';
import HighlightTagsIcon from '../icons/HighlightTagsIcon';
import AnnotationPopovers from '../Popovers/AnnotationPopovers';
import Tooltip from '../Tooltip';
import {
  copyHighlightContent,
  createHighlightHashId,
  HIGHLIGHT_HASH_PREFIX,
  invokeGhostreader,
  parseHighlightIdFromHash,
  triggerHighlightPopover,
} from './notebookHelpers';
import styles from './NotebookView.module.css';

const logger = makeLogger(__filename);

export function removeConsecutiveHeadings(contentItems: NotebookContentItem[]): NotebookContentItem[] {
  const filteredContentItems: NotebookContentItem[] = [];
  let previousItem: NotebookContentItem | undefined;
  for (const item of contentItems) {
    if (item.type === 'highlight') {
      if (previousItem && previousItem.type === 'heading') {
        // Only add headings that come directly before a highlight.
        filteredContentItems.push(previousItem);
      }
      filteredContentItems.push(item);
    }
    previousItem = item;
  }
  return filteredContentItems;
}

function HeadingBlock({ heading }: {
  heading: NotebookContentHeading;
}): ReactElement {
  const HeadingTag = `h${heading.level}` as keyof JSX.IntrinsicElements;
  return <HeadingTag className={styles.heading}>
    {heading.content}
  </HeadingTag>;
}

function HighlightBlock({ highlight, setRef }: {
  highlight: NotebookContentHighlight;
  setRef?: (ref: HTMLDivElement | null) => void;
}): ReactElement {
  const history = useHistory();
  const highlightContainerRef = useRef<HTMLDivElement>(null);

  const focusThisHighlight = useCallback(() => {
    history.replace({ hash: createHighlightHashId(highlight.id) });
  }, [history, highlight.id]);

  const onHighlightTextClicked = useCallback(() => {
    triggerHighlightPopover(null, highlight.id);
    focusThisHighlight();
  }, [focusThisHighlight, highlight.id]);

  const onTagsIconClicked = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    triggerHighlightPopover('tag', highlight.id);
    focusThisHighlight();
  }, [focusThisHighlight, highlight.id]);

  const onNoteIconClicked = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    triggerHighlightPopover('note', highlight.id);
    focusThisHighlight();
  }, [focusThisHighlight, highlight.id]);

  const hasNote = useMemo(() => (highlight.children?.length ?? 0) !== 0, [highlight.children]);
  const hasTag = useMemo(() => Object.keys(highlight.tags ?? {}).length !== 0, [highlight.tags]);

  useEffect(() => {
    if (!setRef) {
      return;
    }
    setRef(highlightContainerRef.current);
  }, [setRef]);

  return <div
    data-highlight-id={highlight.id}
    className={styles.highlightBlock}
    onClick={onHighlightTextClicked}
    onKeyDown={onHighlightTextClicked}
    ref={highlightContainerRef}
  >
    <span className={highlight.content?.length ? 'rw-pseudo-highlight' : styles.imageHighlightContainer}>
      <span dangerouslySetInnerHTML={{ __html: highlightMarkdownToHtml(highlight.markdown) }} />
      <span aria-hidden="true" >
        <Tooltip content="Edit note" shortcut="N" >
          <Button onClick={onNoteIconClicked} >
            <HighlightNoteIcon className={hasNote ? styles.iconButton : styles.hiddenButton} />
          </Button>
        </Tooltip>
        <Tooltip content="Edit tags" shortcut="T" >
          <Button onClick={onTagsIconClicked} >
            <HighlightTagsIcon className={hasTag ? styles.iconButton : styles.hiddenButton} />
          </Button>
        </Tooltip>
      </span>
      </span>
  </div>;
}

export type NotebookContentHeading = {
  type: 'heading';
  level: number;
  content: string;
  location: string;
};
export type NotebookContentHighlight = {
  type: 'highlight';
} & Pick<Highlight, 'id' | 'location' | 'content' | 'markdown' | 'children' | 'tags' | 'parent' | 'offset'>;

export type NotebookContentItem = NotebookContentHighlight | NotebookContentHeading;
const FOCUSED_ITEM_SCROLL_OFFSET_FROM_TOP = 300;

export function NotebookContentView({
  contentItems,
  scrollableAncestorRef,
  setFocusedItemIndex: onFocusedItemIndexUpdated,
}: {
  contentItems: NotebookContentItem[];
  scrollableAncestorRef: React.RefObject<HTMLDivElement>;
  setFocusedItemIndex: (index: number) => void;
}): ReactElement {
  useAppearanceStyles();

  const blocksContainerRef = useRef<HTMLDivElement>(null);
  const [highlightElements, setHighlightElements] = useState<TextHighlightElement[]>([]);
  const [canRenderPopovers, setCanRenderPopovers] = useState(false);
  const [highlightBlockForId, setHighlightBlockForId] = useState<{ [id: string]: HTMLDivElement; }>({});
  const indicatorRef = useRef<HTMLDivElement>(null);

  // The flow of data for focused item is: URL hash -> focusedItemIndex -> content focus indicator positioning.
  // Up and down keys only update the URL hash, not focusedItemIndex.
  // This way we can easily support three different ways of focusing an item:
  // 1. URL hash on first page load, 2. clicking ToC link, and 3. arrow keys.
  const history = useHistory();
  const [focusedItemIndex, setFocusedItemIndex] = useState(0);

  const findNextHighlightItemIndex = useCallback((startIndex: number, increment: number): number => {
    let nextIndex = startIndex;
    // skip headings. only need to skip at most once since we removed consecutive headings.
    if (contentItems[nextIndex]?.type === 'heading') {
      nextIndex += increment;
    }
    nextIndex = Math.max(0, Math.min(nextIndex, contentItems.length - 1));
    return nextIndex;
  }, [contentItems]);

  const setLocationHashFromItemIndex = useCallback((index: number) => {
    if (contentItems.length === 0) {
      logger.debug('setLocationHashFromItemIndex: bailing, content items still empty', { index });
      return;
    }
    const contentItem = contentItems[index];
    if (!contentItem || contentItem.type !== 'highlight') {
      logger.debug('setLocationHashFromItemIndex: bailing, expected highlight at index', { contentItem, index, contentItems });
      return;
    }
    history.replace({ hash: createHighlightHashId(contentItem.id) });
  }, [contentItems, history]);

  useEffect(() => {
    const hash = history.location.hash;
    let itemIndexFromHash: number;
    let newFocusedItemIndex: number;
    if (hash.startsWith(`#${HIGHLIGHT_HASH_PREFIX}`)) {
      const highlightIdFromHash = parseHighlightIdFromHash(hash);
      itemIndexFromHash = contentItems.findIndex((item) => item.type === 'highlight' && item.id === highlightIdFromHash);
      if (itemIndexFromHash !== -1) {
        newFocusedItemIndex = itemIndexFromHash;
      } else {
        logger.debug('No highlight with ID from URL hash', { highlightIdFromHash, hash });
        newFocusedItemIndex = findNextHighlightItemIndex(focusedItemIndex, +1);
      }

    } else {
      itemIndexFromHash = parseHeadingIdToIndex(hash) ?? 0;
      newFocusedItemIndex = findNextHighlightItemIndex(itemIndexFromHash, +1);
    }

    if (newFocusedItemIndex !== itemIndexFromHash) {
      setLocationHashFromItemIndex(newFocusedItemIndex);
    }

    logger.debug('Setting focused item index from URL hash', { hash, newFocusedItemIndex, itemIndexFromHash, focusedItemIndex });
    if (newFocusedItemIndex !== focusedItemIndex) {
      setFocusedItemIndex(newFocusedItemIndex);
    }
  }, [setLocationHashFromItemIndex, contentItems, findNextHighlightItemIndex, focusedItemIndex, setFocusedItemIndex, history.location.hash]);

  useEffect(() => {
    onFocusedItemIndexUpdated(focusedItemIndex);
  }, [onFocusedItemIndexUpdated, focusedItemIndex]);

  useEffect(() => {
    const highlightElements = contentItems
      .map((item) => item.type === 'highlight' ? highlightBlockForId[item.id] : null)
      .filter(notEmpty) as TextHighlightElement[];
    setHighlightElements(highlightElements);
    logger.debug('Setting highlight elements for popovers', { highlightElements });

    const timeout = setTimeout(() => setCanRenderPopovers(true), 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [setCanRenderPopovers, setHighlightElements, highlightBlockForId, contentItems]);

  const shortcutsMap = useShortcutsMap();

  useHotkeys(
    shortcutsMap[ShortcutId.MoveDownFocusIndicator],
    (e) => {
      e.preventDefault();
      const nextIndex = findNextHighlightItemIndex(focusedItemIndex + 1, +1);
      setLocationHashFromItemIndex(nextIndex);
    },
  );
  useHotkeys(
    shortcutsMap[ShortcutId.MoveUpFocusIndicator],
    (e) => {
      e.preventDefault();
      const nextIndex = findNextHighlightItemIndex(focusedItemIndex - 1, -1);
      setLocationHashFromItemIndex(nextIndex);
    },
  );

  const focusedHighlightItem: NotebookContentHighlight | null = useMemo(() => {
    const item = contentItems[focusedItemIndex];
    if (!item || item.type !== 'highlight') {
      return null;
    }
    return item;
  }, [contentItems, focusedItemIndex]);
  const focusedHighlightBlock = useMemo(() => {
    if (!focusedHighlightItem) {
      return null;
    }
    return highlightBlockForId[focusedHighlightItem.id];
  }, [focusedHighlightItem, highlightBlockForId]);

  useEffect(() => {
    const scrollableAncestor = scrollableAncestorRef.current;
    if (!indicatorRef.current || !blocksContainerRef.current || !focusedHighlightBlock || !scrollableAncestor) {
      return;
    }
    logger.debug('Moving focus indicator', { focusedHighlightBlock });
    positionFocusIndicator(indicatorRef.current, focusedHighlightBlock, blocksContainerRef.current);
    const newScrollTop = focusedHighlightBlock.getBoundingClientRect().top + scrollableAncestor.scrollTop - FOCUSED_ITEM_SCROLL_OFFSET_FROM_TOP;
    scrollableAncestor.scroll({
      top: newScrollTop,
      left: 0,
      behavior: 'smooth',
    });
  }, [focusedHighlightBlock, focusedHighlightBlock?.clientHeight, scrollableAncestorRef]);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const onConfirmDelete = useCallback(() => {
    if (!focusedHighlightItem?.id) {
      return;
    }
    deleteHighlight(focusedHighlightItem.id, { userInteraction: 'unknown' });
    setIsDeleteDialogOpen(false);
  }, [focusedHighlightItem?.id]);
  const deleteHighlightDialog: JSX.Element = useMemo(() =>
    <DeleteHighlightDialog
      isOpen={isDeleteDialogOpen}
      onCancel={() => setIsDeleteDialogOpen(false)}
      onConfirm={onConfirmDelete} />,
    [isDeleteDialogOpen, onConfirmDelete]);

  useHotKeys(
    shortcutsMap[ShortcutId.EditHighlightNoteInNotebook],
    useCallback(() => {
      triggerHighlightPopover('note', focusedHighlightItem?.id);
    }, [focusedHighlightItem?.id]),
    {
      description: 'Edit highlight note',
    },
  );

  useHotKeys(
    shortcutsMap[ShortcutId.EditHighlightTagsInNotebook],
    useCallback(() => {
      triggerHighlightPopover('tag', focusedHighlightItem?.id);
    }, [focusedHighlightItem?.id]),
    {
      description: 'Edit highlight tags',
    },
  );

  const [note] = useHighlightNoteText(focusedHighlightItem);

  useHotKeys(
    shortcutsMap[ShortcutId.DeleteHighlightInNotebook],
    useCallback((event) => {
      event.stopPropagation();
      setIsDeleteDialogOpen(true);
    }, []),
    {
      description: 'Delete highlight',
    },
  );

  useHotKeys(
    shortcutsMap[ShortcutId.CopyNotebookHighlightText],
    useCallback((event) => {
      event.stopPropagation();
      copyHighlightContent(focusedHighlightItem?.content);
    }, [focusedHighlightItem?.content]),
    {
      description: 'Copy highlight text',
    },
  );

  useHotKeys(
    shortcutsMap[ShortcutId.CopyNotebookHighlightNote],
    useCallback((event) => {
      // without this preventDefault, the inspect menu opens everytime
      event.preventDefault();
      copyHighlightContent(note);
    }, [note]),
    {
      description: 'Copy highlight note',
    },
  );

  useHotKeys(
    shortcutsMap[ShortcutId.ViewNotebookHighlightInDocument],
    useCallback((event) => {
      event.stopPropagation();
      if (!focusedHighlightItem) {
        return;
      }
      setHighlightIdToOpenAt(focusedHighlightItem?.id, { userInteraction: 'unknown' });
      history.push(urlJoin(['/read', focusedHighlightItem.parent]));
    }, [history, focusedHighlightItem]),
    {
      description: 'View highlight in document',
    },
  );

  useHotKeys(
    shortcutsMap[ShortcutId.Ghostreader],
    useCallback((event) => {
      event.preventDefault();
      if (!focusedHighlightItem) {
        return;
      }
      invokeGhostreader(focusedHighlightItem);
    }, [focusedHighlightItem]),
    {
      description: 'Invoke Ghostreader',
    },
  );

  const contentBlocks = useMemo(() =>
    contentItems.map((item) => {
      let block: ReactElement;
      let id: string | undefined;
      let key: string;
      switch (item.type) {
        case 'highlight':
          id = createHighlightHashId(item.id);
          key = item.id;
          block = <HighlightBlock
            highlight={item}
            setRef={(ref) => {
              if (!ref) {
                return;
              }
              setHighlightBlockForId((highlightBlockForId) => {
                const newHighlightBlockForId = clone(highlightBlockForId);
                newHighlightBlockForId[item.id] = ref;
                return newHighlightBlockForId;
              });
            }} />;
          break;
        case 'heading':
          key = item.location;
          block = <HeadingBlock key={item.location} heading={item} />;
          break;
      }
      return <div key={key} id={id}>
        {block}
      </div>;
    }), [contentItems]);

  return <div>
    <div ref={blocksContainerRef} id="notebook-content" className={styles.notebookContent}>
      {contentBlocks}
    </div>
    {canRenderPopovers &&
      <HighlighterPopovers
        containerNodeSelector="#notebook-content"
        eventEmitter={foregroundEventEmitter}
        highlightElements={highlightElements}
        shouldAppendSelectionAnnotationPopovers={false}
        renderPopover={({ hidePopover, isShown, showPopover, ...props }) => <AnnotationPopovers
          hideAnnotationBarPopover={hidePopover}
          isAnnotationBarPopoverShown={isShown}
          key={props.highlightId}
          showAnnotationBarPopover={showPopover}
          isNotebookView
          {...props}
        />}
      />}
    <div
      className={`${contentFocusIndicatorStyles.root} ${contentFocusIndicatorStyles.rootShown}`}
      ref={indicatorRef}
    />
    {deleteHighlightDialog}
  </div>;
}
